import React from 'react'
import '@fortawesome/fontawesome-free/css/all.css';
import Sobre from '../components/Sobre';
import Skins from '../components/Skins';
import Xp from '../components/Xp'
import Contato from '../components/Contato';

const Menu = () => {
  const nome = '< Diego Oliveira />'

  return (
    <div style={{marginTop: '100px'}}>
      <nav className="navbar navbar-light nav-css fixed-top nav-bar-desktop">
        <div className="container-fluid">
          <p className="navbar-brand nome-titulo">{nome}</p>
          <div className="d-flex">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-flex flex-row">
              <li className="nav-item m-2">
                <a className="nav-link" href="#">Sobre mim</a>
              </li>
              <li className="nav-item m-2">
                <a className="nav-link" href="#skin">Skills</a>
              </li>
              <li className="nav-item m-2">
                <a className="nav-link" href="#xp">Experiência</a>
              </li>
              <li className="nav-item m-2">
                <a className="nav-link" href="#contato">Contatos</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <nav className="navbar navbar-light nav-css fixed-top nav-bar-mobile">
        <div className="container-fluid">
            <div className="dropdown">
              <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                <i className="fa-solid fa-bars"></i>
              </button>
              <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton1">
                <li><a className="dropdown-item" href="#">Sobre mim</a></li>
                <li><a className="dropdown-item" href="#skin">Skills</a></li>
                <li><a className="dropdown-item" href="#xp">Experiência</a></li>
                <li><a className='dropdown-item' href="#contato">Contatos</a></li>
              </ul>
            </div>
          <div className="d-flex">
            <p className="navbar-brand nome-titulo">{nome}</p>
          </div>
        </div>
      </nav>


      <div id="sobre">
        <Sobre />
      </div>
      <div id="skin">
        <Skins />
      </div>
      <div id="xp">
        <Xp />
      </div>
      <div id="contato">
        <Contato />
      </div>
    </div>
  )
}

export default Menu
