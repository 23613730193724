
import React from 'react';
import '../../src/contato.css';
import { ReactComponent as Dev}  from '../img/dev-ilustration.svg'

function Contato() {
    const enderecoEmail = 'diegojof95@hotmail.com'
    return (
        <div className='cntn'>
            <div className='container mt-3'>
                <h3 className='gradient-text'>Contatos</h3>
                <div className='row'>
                    <div className='col-md-6 container-redes-sociais'>
                        <div className='container-contato'>
                            <a href='https://www.linkedin.com/in/diego-oliveira-fonseca/' target='_blanck' title='Linkedin'>
                                <i style={{color: '#0a66c2'}} class="fa-brands fa-linkedin icon-rede"></i>
                            </a>
                            <a href='https://github.com/Diego0liver' target='_blanck' title='GitHub'>
                                <i style={{color: '#1f2328'}} class="fa-brands fa-square-github icon-rede"></i>
                            </a>
                            <a href='https://wa.me/5544991763101' target='_blanck' title='WhatsApp'>
                                <i style={{color: '#44c052'}} class="fa-brands fa-square-whatsapp icon-rede"></i>
                            </a>
                            <a href={`mailto:${enderecoEmail}`} target='_blanck' title='diegojof95@hotmail.com'>
                                <i style={{color: '#223e8b'}} class="fa-solid fa-envelope icon-rede"></i>
                            </a>
                            <a href='https://www.youtube.com/channel/UCRuXFNX9XQcSsWDyIubzbGg' target='_blanck' title='YouTube'>
                                <i style={{color: '#ff2b28'}} class="fa-brands fa-youtube icon-rede"></i>
                            </a>
                        </div>
                        <div className='container-cv'>
                            <a href='https://drive.google.com/file/d/181_fXDzcLsdv_Eo8CGyc0T4y1JnrbiMy/view?usp=drive_link' target="_blank" title='Curriculo' >
                                <button className='btn-cv'><i class="fa-solid fa-circle-arrow-down"></i> &nbsp; Currículo</button>
                            </a>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <Dev className='svg-ilustration' />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contato;
