
import React, { useState, useEffect } from 'react';
import Eu from '../img/eu2024.png'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';

function Sobre() {
    const codeSnippet = `
  public class Programador {
    nome = 'Diego Oliveira';
    idade = '29 anos';
    cidade = 'Maringa/PR';
    cursando = 'Analise e Desenvolvimento de Sistemas'
    faculdade = 'UNESA';
    profissão = 'Programador Web';
  }
`;
    const [text, setText] = useState(""); 
    const [isDeleting, setIsDeleting] = useState(false); 
    const [loopNum, setLoopNum] = useState(0); 
    const [typingSpeed, setTypingSpeed] = useState(100); 
    const frases = ["Olá, sou Diego!", "Bem vindo ao meu portfólio."]; 
    useEffect(() => {
        const handleTyping = () => {
          const currentPhrase = frases[loopNum % frases.length]; 
          const updatedText = isDeleting
            ? currentPhrase.substring(0, text.length - 1) 
            : currentPhrase.substring(0, text.length + 1); 
    
          setText(updatedText);
    
          if (isDeleting) {
            setTypingSpeed(50); 
          } else {
            setTypingSpeed(150); 
          }
        
          if (!isDeleting && updatedText === currentPhrase) {
            setTimeout(() => setIsDeleting(true), 1000);
          } else if (isDeleting && updatedText === "") {
            setIsDeleting(false);
            setLoopNum(loopNum + 1); 
          }
        };
    
        const timer = setTimeout(handleTyping, typingSpeed); 
    
        return () => clearTimeout(timer); 
      }, [text, isDeleting, typingSpeed, loopNum, frases]);

    return (
        <div className='container'>
            <div className='row'>
                <div className='col-md-6 cont'>
                    <img alt='dev' src={Eu} className='eu' />
                </div>
                <div className='col-md-6 cont2'>
                    <div className="typing-container">
                        <h1 className='ola'>
                            {text}
                            <span className="cursor">|</span> {/* Cursor piscante */}
                        </h1>
                    </div>
                    <SyntaxHighlighter language="php" style={darcula}>
                        {codeSnippet}
                    </SyntaxHighlighter>
                    <div>
                        <a href='https://drive.google.com/file/d/181_fXDzcLsdv_Eo8CGyc0T4y1JnrbiMy/view?usp=drive_link' target="_blank" title='Curriculo' >
                            <button className='btn-cv'><i class="fa-solid fa-circle-arrow-down"></i> &nbsp; Currículo</button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sobre;
