import React from 'react'
import Menu from './Home/Menu'
import {  Route, Routes  } from "react-router-dom";

const App = () => {
  return (<>
    <Routes>
      <Route path='/' element={<Menu/>} />
    </Routes>
    </>
  )
}

export default App